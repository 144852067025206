
import Vue from "vue";
import { IReportHeaderTemplate, projects } from "@/api";
import { Actions } from "@/store/models";
import { bufferToImage, getDate } from "@/utils";
import GmapAddressAutocomplete from "@/components/common/GmapAddressAutocomplete.vue";

export default Vue.extend({
  name: "ReportHeaderTemplateDialog",
  components: {
    GmapAddressAutocomplete,
  },
  props: {
    dialog: Boolean,
    item: Object,
  },
  data() {
    return {
      report: {} as IReportHeaderTemplate,
    };
  },
  computed: {
    lastUpdated: function (): string {
      return getDate(
        this.report.updated_datetime || this.report.created_datetime
      );
    },
    logo: function (): string {
      const icon = this.report.company_icon || ({} as any);
      return icon.data ? bufferToImage(icon.data) : "";
    },
    url: function (): string {
      if (
        !this.report.company_icon ||
        this.report.company_icon.type === "Buffer"
      ) {
        return "";
      }
      return window.URL.createObjectURL(
        new Blob([this.report.company_icon as any], { type: "image/png" })
      );
    },
  },
  methods: {
    async save() {
      if (!this.report.name) {
        this.$store.dispatch(
          Actions.DisplayError,
          "The template must have a name to be saved."
        );
        return;
      }
      try {
        if (!this.item) {
          await projects.reportHeaderTemplates.add(this.report);
        } else {
          await projects.reportHeaderTemplates.update(
            this.item.id,
            this.report
          );
        }
        this.$emit("updated");
        this.$emit("toggleDialog", false);
        this.report = {} as any;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },

  watch: {
    item: {
      deep: true,
      immediate: true,
      handler() {
        this.report = this.item || {};
      },
    },
  },
});
