
import Vue from "vue";
import { IReportHeaderTemplate, multiApiOperations, projects } from "@/api";
import { Actions } from "@/store/models";
import { getDate } from "@/utils";
import ReportHeaderTemplateDialog from "@/components/reportHeaderTemplates/ReportHeaderTemplateDialog.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";

export default Vue.extend({
  name: "ReportHeaderTemplates",
  components: {
    ReportHeaderTemplateDialog,
    ConfirmationDialog,
  },
  data() {
    return {
      templateDialog: false,
      templateEditDialog: false,
      removeConfirmationDialog: false,
      index: 1,
      selected: [],
      headers: [
        { text: "NAME", value: "name" },
        { text: "LAST UPDATED", value: "dateEdited" },
        { text: "", value: "action" },
      ],
      templates: [] as IReportHeaderTemplate[],
      items: [] as any,
      item: {} as IReportHeaderTemplate,
      searchQuery: ''
    };
  },
  created() {
    this.getItems();
  },
  computed: {
    selectedIds: function (): number[] {
      return this.selected.map((p: { id: number }) => p.id);
    },
    filteredItems: function ():any {
      return this.items.filter((item: any) => {
        return (
          item.name.includes(this.searchQuery) 
        );
      });
    },
  },
  methods: {
    toggleTemplateDialog(val: boolean) {
      this.templateDialog = val;
    },
    toggleTemplateEditDialog(val: boolean) {
      this.templateEditDialog = val
    },
    async getItems() {
      try {
        this.items = [];
        this.templates = (await projects.reportHeaderTemplates.getAll()).data;
        this.templates.forEach((r) => {
          this.items.push({
            name: r.name,
            dateEdited: getDate(r.updated_datetime || r.created_datetime),
            id: r.id,
          });
        });
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError);
      }
    },

    async remove() {
      try {
        await multiApiOperations(projects.reportHeaderTemplates.delete, this.selectedIds);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError);
      }
      this.getItems();
      this.$emit("getActivityLog");
      this.$store.dispatch(Actions.GetCompanyProjects);
      this.selected = []
    },

    edit(id: number) {
      this.templateEditDialog = true
      this.item = this.templates.find(t => t.id === id)!
    },
  },
});
