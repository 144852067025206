
import Vue from "vue";

export default Vue.extend({
  name: "GmapAddressAutocomplete",
  props: {
    address: String,
    enableIcon: Boolean
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    keyup(event: any) {
      // console.log(event.target.value);
      this.$emit("update:address", event.target.value);
    },
    setAddress(address: any) {
      // console.log(address);
      this.$emit("update:address", address.formatted_address);
    },
  },
  watch: {},
});
